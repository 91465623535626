import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

export const Input = styled.input({
  borderRadius: "10px",
  border: "none",
  color: colors.primaryTextColor,
  width: "100%",
  margin: "0 1em",
  padding: "0.5em",
  lineHeight: "3",
  height: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  "::placeholder": {
    opacity: "0.8",
  },
});

export const textArea = css({
  fontFamily: "inherit",
  backgroundColor: colors.tertiaryBackground,
  borderRadius: "10px",
  color: colors.primaryTextColor,
  margin: "0 1em",
  lineHeight: "1.5",
  textOverflow: "ellipsis",
  "::placeholder": {
    opacity: "0.5",
  },
  border: "none",
  outline: "none",
  resize: "none",
  appearance: "none",
  fontSize: "1em",
  width: "100%",
  maxWidth: "100%",
  maxHeight: "8em",
  overflowY: "auto",
  padding: "2px",
  cursor: "text",
});
