import { css } from "@emotion/react";
import styled, { CSSObject } from "@emotion/styled";
import Color from "color";
import { colors } from "../../styles/global.styles";

export const DropdownMenu = styled.ul({
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  whiteSpace: "nowrap",
  alignItems: "baseline",
  backgroundColor: "#000E1A",
  color: colors.primaryTextColor,
  position: "absolute",
  paddingRight: "1vw",
  paddingLeft: "1vw",
  borderRadius: "10px 10px 10px 10px",
  paddingTop: "1vh",
});

export const dropdownItem: CSSObject = {
  height: "auto",
  ":hover": {
    color: colors.primaryAccentColor,
  },
  transition: "color 0.15s ease",
  paddingBottom: "1vh",
};

export const dropdownContainer: CSSObject = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "8px",
  overflow: "hidden",
  background: colors.secondaryBackground,
  gap: "1px",
  border: `solid 1px ${colors.secondaryBackground}`,
  transition: "opacity 0.1s ease",
  fontSize: "0.95em",
  lineHeight: "1",
};

export const deleteDropdownContainer: CSSObject = {
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "8px",
  overflow: "hidden",
  background: colors.secondaryBackground,
  gap: "1px",
  border: `solid 1px ${colors.secondaryBackground}`,
  transition: "opacity 0.1s ease",
  fontSize: "0.95em",
  lineHeight: "1",
  zIndex: "1",
};

export const footerDropdown: CSSObject = {
  position: "absolute",
  bottom: "calc(100% + 0.5em)",
  left: "0",
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  height: "fit-content",
  borderRadius: "8px",
  overflow: "hidden",
  background: colors.secondaryBackground,
  gap: "1px",
  border: `solid 1px ${colors.secondaryBackground}`,
  transition: "opacity 0.1s ease",
  fontSize: "0.95em",
  lineHeight: "1",
};

export const headerDropdown: CSSObject = {
  top: "calc(100% + 0.5em)",
  right: "0",
};

export const footerDropdownItem: CSSObject = {
  height: "2em",
  minWidth: "6em",
  background: colors.tertiaryBackground,
  padding: "2em 2.3em",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  gap: "0.45em",
  "& > svg": {
    fontSize: "1.25em",
  },
  ":hover": {
    background: new Color(colors.secondaryBackground).lighten(0.1).toString(),
  },
  transition: "background 0.25s ease",
  ":disabled": {
    color: colors.secondaryTextColor,
  },
};

export const primaryText: CSSObject = {
  color: colors.primaryTextColor,
  maxWidth: "10em",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

export const secondaryText: CSSObject = {
  color: colors.primaryAccentColor,
};

export const sectionHeaderDropdownItemStyle = css({
  height: "2em",
  minWidth: "6em",
  background: colors.tertiaryBackground,
  padding: "1.3em 1.3em",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  gap: "0.45em",
  //TODO change color on hover to background color and make sure to do the same change for the right background color for contact cards and messaging cards
  ":hover": {
    color: colors.primaryAccentColor,
  },
  transition: "background 0.25s ease",
  ":disabled": {
    color: colors.secondaryTextColor,
  },
});
